import React, { useState } from 'react';

import { useResize } from 'hooks/use-resize';

interface IProps {
  children: React.ReactNode;
}

export interface IUiContext {
  isMobile: boolean | null;
  isDesktop: boolean | null;
  navOpen: boolean;
  navOpenFromHamburger: boolean;
  toggleNav: (open: boolean) => void;
  toggleNavFromHamburger: (status: boolean) => void;
  imagesPreloaded: boolean;
  setImagesPreloaded: (imagesPreloaded: boolean) => void;
  canTransition: boolean;
  setCanTransition: (canTransition: boolean) => void;
  activeDriverSection: number;
  setActiveDriverSection: (activeDriverSection: number) => void;
  isDriverPage: boolean;
  setIsDriverPage: (isDriverPage: boolean) => void;
  desktopModal: boolean,
  toggleDesktopModal: (bool: boolean)  => void;
}

export const UIContext = React.createContext<IUiContext>({
  isMobile: null,
  isDesktop: null,
  navOpen: false,
  navOpenFromHamburger: false,
  toggleNav: (open: boolean) => !open,
  toggleNavFromHamburger: (status: boolean) => status,
  imagesPreloaded: true,
  setImagesPreloaded: (imagesPreloaded: boolean) => !imagesPreloaded,
  canTransition: true,
  setCanTransition: (canTransition: boolean) => !canTransition,
  activeDriverSection: 1,
  setActiveDriverSection: (activeDriverSection: number) => activeDriverSection,
  isDriverPage: false,
  setIsDriverPage: (isDriverPage: boolean) => isDriverPage,
  desktopModal: false,
  toggleDesktopModal: (bool: boolean)  => bool,
});

export const UIProvider = ({ children }: IProps) => {
  const [navOpen, setNavOpen] = React.useState(false);
  const [navOpenFromHamburger, setnavOpenFromHamburger] = React.useState(false);
  const [imagesPreloaded, setImagesPreloaded] = useState(false);
  const [canTransition, setCanTransition] = useState(true);
  const [activeDriverSection, setActiveDriverSection] = useState( (typeof window !== 'undefined' && (location.hash==='#approach' ||  location.hash === '#ecosystem' ) ) ? 2 : 1 );
  const [isDriverPage, setIsDriverPage] = useState(false);
  const [desktopModal, toggleDesktopModal] = useState(false);
  const [activeRoute, setActiveRoute] = useState('');

  const preventScroll = (prevent: boolean) => {
    const htmlClassName = 'scroll-disabled';

    prevent
      ? document.documentElement.classList.add(htmlClassName)
      : document.documentElement.classList.remove(htmlClassName);

    /*if(prevent){
      document.documentElement.classList.add(htmlClassName)
      document.body.style.color = 'red';
      if(isMobile){
        setTimeout( () => {
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = 'absolute';
        }, 1000 )
      }
    }else{
      if(isMobile){
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        setTimeout( () => {
          document.documentElement.classList.remove(htmlClassName)
        }, 1100 )
      }else{
        document.documentElement.classList.remove(htmlClassName);
      }
    }*/
    
  };

  const toggleNav = (open: boolean) => {
    preventScroll(open);
    setNavOpen(open);
  };

  const toggleNavFromHamburger = (status: boolean) => {
    setnavOpenFromHamburger(status);
  };


  const resize = useResize();
  const { isMobile, isDesktop } = resize;

  return (
    <UIContext.Provider
      value={{
        isMobile,
        isDesktop,
        navOpen,
        navOpenFromHamburger,
        toggleNav,
        toggleNavFromHamburger,
        imagesPreloaded,
        setImagesPreloaded,
        canTransition,
        setCanTransition,
        activeDriverSection,
        setActiveDriverSection,
        isDriverPage,
        setIsDriverPage,
        desktopModal,
        toggleDesktopModal,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const withUIContext = (Component: any) => (props: any) => (
  <UIContext.Consumer>
    {context => <Component {...props} ui={context} />}
  </UIContext.Consumer>
);
