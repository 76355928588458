import React, { ReactNode, useRef, useContext, useEffect } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import { UIContext } from 'context/ui';
import { useResize } from 'hooks/use-resize';

interface IProps {
  children: ReactNode;
  location: {
    pathname: string;
    state?: any;
  };
  wipeRef: HTMLDivElement;
}

export const PageTransition = ({ children, location, wipeRef }: IProps) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const wipe = wipeRef.current
  const { canTransition, navOpen, isMobile, isDesktop } = useContext<any>(UIContext)
  const resize = useResize();
  const { sizes } = resize;
  let scrollTriggerInstances: Array<any> = []

  useEffect( () => {
    isDesktop && gsap.set(wipe, { y: -window.innerHeight, x:0 });
    isMobile && gsap.set(wipe, { x: -window.innerWidth, y:0 });
  }, [sizes] )

  const onEnter = (to: HTMLElement) => {
    
    if (canTransition) {
      
      window.reactTransitionGroupTriggered = true

      // disable scroll
      window.smoothScroll.disable()

      // store old page scrollTrigger instances to kill when the old page is removed
      ScrollTrigger.getAll().forEach( (t) => {
        scrollTriggerInstances.push(t)
      });

      // hide next view
      to.classList.add('transitioning')

      // Transition 1: VEIL/UNVEIL
      if (isDesktop) {
        // animate in wipe overlay
        gsap.set(wipe, { autoAlpha: 1, y: window.innerHeight, visibility: 'inherit'});
        gsap.to(wipe, { duration: 1.25, ease: 'connect-easing3', y: 0});
        gsap.to('[data-scroll-container]', { duration: 1, delay: 0.25, force3D:true, ease: 'connect-easing3', y:-40, onComplete: () => {
          gsap.set('[data-scroll-container]', {y: 0});
        }});
        
        // animate out wipe overlay
        gsap.to(wipe, {
          duration:  1.5,
          ease: 'connect-easing2', //'Power3.easeOut',
          delay: 1.2,
          y: -window.innerHeight,
          //opacity: 0,
          onComplete: () => {
            gsap.set(wipe, { visibility: 'hidden'});
          }
        });
      }

/*      
      // Transition 2: STRAIGHT
      if (isDesktop) {
        // animate in wipe overlay
        //gsap.set(wipe, { autoAlpha: 0, visibility: 'inherit'});
        //gsap.to(wipe, { duration: 0.5, ease: 'connect-easing3', autoAlpha: 1});
        // animate in wipe overlay
        gsap.set(wipe, { autoAlpha: 1, y: window.innerHeight, visibility: 'inherit'});
        gsap.to(wipe, { duration: 1.25, ease: 'connect-easing3', y: 0});
        gsap.to('[data-scroll-container]', { duration: .9, delay: 0.35, force3D:true, ease: 'connect-easing3', y:-40, onComplete: () => {
          gsap.set('[data-scroll-container]', {y: 0});
        }});
        
        // animate out wipe overlay
        gsap.to(wipe, {
          duration:  1.25,
          ease: 'connect-easing3',
          delay: 1,
          opacity: 0,
          onComplete: () => {
            gsap.set(wipe, { visibility: 'hidden'});
          }
        });
      }
  */    

      /*
      if (isMobile && !navOpen) {
        // animate in wipe overlay
        gsap.set(wipe, { autoAlpha: 1, x: window.innerWidth });
        gsap.to(wipe, { duration: 1, ease: 'Power3.easeIn', x: 0 });

        // animate out wipe overlay
        gsap.to(wipe, {
          duration: 1,
          ease: 'Power3.easeOut',
          delay: 1.3,
          x: -window.innerWidth,
        });
      }
      */

     if (isMobile && !navOpen) {
      // animate in wipe overlay
      gsap.set(wipe, { autoAlpha: 1, x: window.innerWidth, visibility: 'inherit'});
      gsap.to(wipe, { duration: 1.25, ease: 'connect-easing3', x: 0});
      gsap.to('[data-scroll-container]', { duration: .9, delay: 0.35, force3D:true, ease: 'connect-easing3', x:-40, onComplete: () => {
        gsap.set('[data-scroll-container]', {x: 0});
      }});
      
      // animate out wipe overlay
      gsap.to(wipe, {
        duration:  0.25,//1.5,
        ease: 'connect-easing3', //'Power3.easeOut',
        delay: 1.2,
        //y: -window.innerHeight,
        opacity: 0,
        onComplete: () => {
          gsap.set(wipe, { visibility: 'hidden'});
        }
      });
    }
    }
  };

  const onEntered = (to: HTMLElement) => {
    
    if (canTransition) {
      // show next view when wipe overlay is covering the viewport
      to.classList.remove('transitioning')

      // reset & re-enable scroll
      if(window.smoothScroll){
        if (window.scrollY < 50) {
          window.smoothScroll.scrollTo(0)
        }
        
        window.smoothScroll && window.smoothScroll.enable(false, true)

        // kill scrollTrigger instances for the page that was removed
        scrollTriggerInstances.forEach( (t) => {
          t.kill()
        });
      }
    }
  };

  return (
    <>
      <TransitionGroup>
        <Transition
          key={location.pathname}
          timeout={900} //1100
          onEnter={onEnter}
          onEntered={onEntered}
          unmountOnExit={true}
        >
          <div ref={parentRef}>{children}</div>
        </Transition>
      </TransitionGroup>
    </>
  );
};