import React, { useEffect, useRef, useContext } from 'react';
import imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import { UIContext } from 'context/ui';
import Logo from 'assets/svg/logo.svg';
import s from './Preloader.scss';

export const Preloader = () => {
  const preloaderRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);
  const { setImagesPreloaded } = useContext(UIContext);

  useEffect(() => {
    imagesLoaded('[data-preload=true]', () => {
      gsap.to(preloaderRef.current, {
        duration: 1,
        ease: 'Power3.easeInOut',
        autoAlpha: 0,
        onComplete: () => {
          setImagesPreloaded(true)
          /*ScrollTrigger.config({ limitCallbacks: true });
          ScrollTrigger.addEventListener("refresh", () => {
            ScrollTrigger.update()
          });
          ScrollTrigger.refresh()*/
        },
      });
    });
  },[]);

  return (
    <div ref={preloaderRef} className={s.preloader}>
      <div ref={logoRef}>
        <Logo className={s.preloader__logo} />
      </div>
    </div>
  );
};
