import { useEffect } from "react"

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import { scroll } from "constants/Scroll"
import { Breakpoints } from 'constants/Breakpoints'

const SmoothScroll = (callbacks:any) => {

  let ASScroll:any
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    ASScroll = require('@ashthornton/asscroll').default;
  }

  useEffect(() => {
    const scrollEnabled = new Event('scrollEnabled');
    // If scroll is already created, don't init again
    if (window.smoothScroll) {
      // If there's a #hash in the url, scroll to its anchor element
      // Resize after page transition
      window.dispatchEvent(scrollEnabled);
      setTimeout( () => window.smoothScroll.onResize(), 1500)
      return;
    }

    // Init & enable
    window.smoothScroll = new ASScroll({
      element: scroll.containerParent,
      innerElement: scroll.container,
      ...scroll.options
    })
    window.smoothScroll.isMobile = window.matchMedia(`(max-width: ${Breakpoints.maxTablet}px)`).matches
    window.smoothScroll.enable()
    window.dispatchEvent(scrollEnabled);
    // Manage #hash on site 1st load, scroll to its anchor element
    /* if(window.location.hash != ''){
      const hashToElement = document.querySelector(`[id=${window.location.hash.replace('#','')}]`)
      hashToElement && setTimeout( () => {
        window.smoothScroll.isMobile ? window.scrollTo(0, hashToElement.offsetTop) : window.smoothScroll.scrollTo(hashToElement.offsetTop)
      }, 1000)
    } */

    // Scroll trigger
    ScrollTrigger.defaults({
      scroller: scroll.container
    })

    ScrollTrigger.scrollerProxy(scroll.container, {
      scrollTop(value) {
        return arguments.length ? window.smoothScroll.scrollTo(value) : -window.smoothScroll.smoothScrollPos;
      },
      getBoundingClientRect() {
        return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
      }
    })

    window.smoothScroll.on("raf", ScrollTrigger.update)
    ScrollTrigger.addEventListener("refresh", () => window.smoothScroll.onResize())
    ScrollTrigger.refresh()
    setTimeout( () => window.smoothScroll.onResize(), 1500)

    // Set scroll direction (up / down)
    window.smoothScroll.on("scroll", ()=>{
      if(window.smoothScroll.prevScrollPos != window.smoothScroll.Scroll.scrollPos && window.smoothScroll.Scroll.scrollPos < 0){
        window.smoothScroll.direction = window.smoothScroll.prevScrollPos < window.smoothScroll.Scroll.scrollPos ? 'up' : 'down'
      }
      window.smoothScroll.prevScrollPos = window.smoothScroll.Scroll.scrollPos
    })

  }, [callbacks])

  return null
}

export default SmoothScroll