import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { UIContext, IUiContext } from 'context/ui';

interface IProps {
  children: React.ReactNode;
  to: string;
  [key: string]: any;
  onClick?: (e: React.SyntheticEvent) => void;
  transition?: boolean;
}

export const Link = ({ children, to, transition = true, ...props }: IProps) => {
  const { setCanTransition, navOpen, toggleNav } = useContext<IUiContext>(
    UIContext
  );

  const isExternal = /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  const onClick = () => {
    if (!isExternal) {
      if (navOpen) {
        setTimeout(
          () => {
            toggleNav(false);
          },
          transition ? 1100 : 0
        );
      }

      setCanTransition(transition);
    }
  };
  if (isExternal) {
    return (
      <a {...props} href={to} target="_blank">
        {children}
      </a>
    )
  }
  return (
    <GatsbyLink to={to} onClick={onClick} {...props} state={{value: true }}>
      {children}
    </GatsbyLink>
  );
};
