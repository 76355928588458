import gsap from 'gsap';
import CustomEase from 'utils/CustomEase/CustomEase';
gsap.registerPlugin(CustomEase);

export const initCustomEasings = () => {

    CustomEase.create('connect-easing1', '.19,1,.22,1');
    CustomEase.create('connect-easing2', '.25,.46,.45,.94');
    CustomEase.create('connect-easing3', '0.650, 0.005, 0.350, 0.995');
    CustomEase.create('hamburger-easing', '0.87, 0, 0.13, 1');
    CustomEase.create('aurora-easing', '0.650, 0.005, 0.350, 0.995');

    //CustomEase.create("elastic", "M0,0 C0.14,0 0.242,0.438 0.272,0.561 0.313,0.728 0.354,0.963 0.362,1 0.37,0.985 0.414,0.873 0.455,0.811 0.51,0.726 0.573,0.753 0.586,0.762 0.662,0.812 0.719,0.981 0.726,0.998 0.788,0.914 0.84,0.936 0.859,0.95 0.878,0.964 0.897,0.985 0.911,0.998 0.922,0.994 0.939,0.984 0.954,0.984 0.969,0.984 1,1 1,1 ");
  
};
