import React, { useEffect, useContext, useRef, useState } from 'react';
import gsap from 'gsap';

import { Prismic_Shared_ContentsuiConnectionConnection } from  'types/prismic';
import { StaticQuery, graphql } from 'gatsby';
import { linkResolver, withPreview } from 'gatsby-source-prismic-graphql';

import { Link } from 'components/link/Link';
import { Nav } from 'components/nav/Nav';
import { DesktopModal } from 'components/nav/desktop-modal/DesktopModal';
import { NavMobile } from 'components/nav/NavMobile';
import { NavButton } from 'components/nav/NavButton';
import { Button } from 'components/button/Button';
import { prismicLinkResolver } from 'utils/linkResolver';

import Logo from 'assets/svg/logo.svg';

import { UIContext } from 'context/ui';

import s from './Header.scss';

interface IHeaderProps {
  prismic: { allShared_contentsuis: Prismic_Shared_ContentsuiConnectionConnection };
}

const HeaderComponent = ({ contentfulData }: any) => {

  const mainNavData = contentfulData.headerLinks;
  const socialNavData = contentfulData.socialLinks;
  const cta = contentfulData.headerCta;


  const headerRef = useRef<HTMLDivElement>(null);
  const driverNavRef = useRef<HTMLElement>(null);
  const { isMobile, isDesktop, navOpen, activeDriverSection,
      setActiveDriverSection, isDriverPage, desktopModal, toggleDesktopModal } = useContext(UIContext);

  const [isHidden, setIsHidden] = useState(false);

  let lastScrollTop = 0;

  const handleScroll = () => {

    const scrollY = window.smoothScroll ? Math.floor(window.smoothScroll.Scroll.scrollPos*-1) : Math.floor(document.documentElement.scrollTop)
    const maxScroll = window.smoothScroll.isMobile ? Math.floor(window.smoothScroll.Scroll.maxScroll*-1) : Math.floor(window.smoothScroll.Scroll.maxScroll*-1)

    if (headerRef.current && !document.documentElement.classList.contains('scroll-disabled')) {

      if (scrollY <= 0) {
        headerRef.current.classList.remove(s.headerHidden)
        headerRef.current.classList.remove(s.headerSticky)

      } else {
        if( scrollY === lastScrollTop || (scrollY > maxScroll && maxScroll > 0 ) || ( lastScrollTop > maxScroll && maxScroll > 0 ) ){
          // Do nothing

        }else if (scrollY > lastScrollTop) {
          // Scrolling down
          toggleDesktopModal(false);
          setIsHidden(true);
          headerRef.current.classList.add(s.headerHidden)
          headerRef.current.classList.remove(s.headerSticky)

        } else {
          // Scrolling up
          setIsHidden(false);
          headerRef.current.classList.remove(s.headerHidden)
          headerRef.current.classList.add(s.headerSticky)

        }
        lastScrollTop = scrollY <= 0 ? 0 : Math.floor(scrollY);
      }
    }
  }

  useEffect(() => {

    setTimeout( () => {
      if(window.smoothScroll){
        window.smoothScroll.on('scroll', handleScroll)
      }else{
        window.addEventListener('scroll', handleScroll)
      }
    }, 100)

    return () => window.smoothScroll ? window.smoothScroll.off('scroll', handleScroll) : window.removeEventListener('scroll', handleScroll)

  }, [])

  let headerSticky = false
  let headerHidden = false
  if (typeof window !== 'undefined' && typeof document !== 'undefined' &&
        window.smoothScroll && window.smoothScroll && window.smoothScroll.direction ) {
    const scrollY = window.smoothScroll ? window.smoothScroll.Scroll.scrollPos*-1 : document.documentElement.scrollTop
    if( scrollY > 0 ){
      if( window.smoothScroll.direction === 'down' && !document.documentElement.classList.contains('scroll-disabled') ){
        headerHidden = true
      }else{
        headerSticky = true
      }
    }
  }

  useEffect(() => {
    const duration = 0.2;

    setTimeout(() => {
      setIsHidden(false);
    }, duration * 1000);
  }, [activeDriverSection]);

  return (
    <>
    <header ref={headerRef} className={s('header', {headerSticky}, {headerHidden})}>
      <div className={s.header__container}>
        <div className={s.header__content}>

          {isMobile && <NavButton style={{ opacity: isHidden ? 0 : 1, top: isDriverPage ? 'calc(50% - 13px)' : '50%' }}>Menu</NavButton>}

          <Link to="/" className={s.header__logo}>
            <span className={`u-visually-hidden`}>Aurora</span>
            <Logo className={s('header__logoSvg', { navOpen })} />
          </Link>

          {isDesktop && <Nav items={mainNavData} />}
          
          {isDesktop && <DesktopModal/>}
          
          {/* {isDesktop && <div className={s.header__featuredBtn}><Button label={cta ? cta.label : ''} href={cta.href || (cta.file && cta.file.file.url)} ></Button></div>} */}
        </div>
      </div>
    </header>
    {isMobile && <NavMobile items={mainNavData} socialItems={socialNavData} />}
    </>
  );
};

export const headerQuery = graphql`
  {
    contentfulData: contentfulNavigation {
      headerLinks {
        label
        destination
      }
      socialLinks {
        destination
        label
      }
      headerCta {
        href
        label
        file {
          file {
            url
          }
        }
      }
    }
  }
`;

export const Header = () => {

  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={HeaderComponent}
    />
  );
};
