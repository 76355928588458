
export const browserTargeting = () => {

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {

    const userAgent = window.navigator.userAgent
    const vendor = window.navigator.vendor

    const IS_EDGE = userAgent.indexOf('Edge') > -1
    const IS_OPERA = userAgent.indexOf('OPR') > -1
    //const IS_FF = userAgent.toLowerCase().indexOf('firefox') > -1
    
    const IS_CHROME =
    (
      userAgent.match('CriOS')
      || 
      (
        !!window.chrome
        &&
        vendor === 'Google Inc.'
        &&
        !IS_OPERA
        &&
        !IS_EDGE
      )
    )

    const IS_SAFARI = userAgent.indexOf('Safari') > -1 && !IS_CHROME

    return {
      IS_SAFARI: IS_SAFARI
    }
  
  }

};

export const isIpad = () => {

  let checkIsIpad;
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    checkIsIpad = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
  }

  return checkIsIpad;

};