import favicon from 'assets/images/favicon.ico';

export const helmet = {
  titleTemplate: '%s | Aurora',
  htmlAttributes: { lang: 'en' },
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'msapplication-navbutton-color', content: '#18264A' },
    { name: 'msapplication-TileColor', content: '#18264A' },
    { name: 'theme-color', content: '#18264A' },
  ],
  link: [{ rel: 'icon', type: 'image/x-icon', href: favicon }],
};
