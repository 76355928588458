const React = require('react');
const { UIProvider } = require('context/ui');


exports.wrapRootElement = ({ element }) => <UIProvider>{element}</UIProvider>;

// page transitions
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  return false;
};

const scrollToElement = (id) => {
  const el = document.querySelector(id);
  if (!el) return;
  const elemRect = el.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();
  const offset = elemRect.top - bodyRect.top;

    if (window.smoothScroll && !window.smoothScroll.isMobile) {
      window.smoothScroll.scrollTo(offset)
    }
    else {
      el.scrollIntoView()
    }
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location.hash !== '') {
    window.addEventListener('scrollEnabled', () => {
      setTimeout(() => {
        scrollToElement(location.hash);
      }, 2000)
      
    })
    
  }
}