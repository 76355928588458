import React, { useRef } from "react";

import { NavLink } from "components/nav/NavLink";
import { PrismicRichText } from "types/prismicRichtext";
import { prismicLinkResolver } from "utils/linkResolver";

import s from "./Nav.scss";

interface IProps {
  items?: Array<IItemProps> | null;
  socialItems?: Array<ISocialItemProps> | null;
}

interface IItemProps {
  global_nav_li_label: PrismicRichText;
  global_nav_li_link: any;
}

export const Nav = ({ items }: any) => {
  const links = items?.map((item: any, index: number) => {
    return (
      <li key={index} className={s.nav__listItem}>
        <NavLink name={item.label} to={item.destination} />
      </li>
    );
  });

  const navRef = useRef<HTMLElement>(null);

  return (
    <nav ref={navRef} className={s.nav}>
      <h2 className="u-visually-hidden" id="main-nav-heading">
        Main Navigation
      </h2>
      <ul className={s.nav__list} aria-labelledby="main-nav-heading">
        <li className={s.nav__listItem}>
          <NavLink name="Aurora Driver" to="/aurora-driver" />
        </li>
        <li className={s.nav__listItem}>
          <NavLink name="Freight" to="/freight" />
        </li>
        <li className={s.nav__listItem}>
          <NavLink name="Safety" to="/safety" />
        </li>
        <li className={s.nav__listItem}>
          <NavLink name="Careers" to="/careers" />
        </li>
        <li className={s.nav__listItem}>
          <NavLink name="News" to="/newsroom" />
        </li>
      </ul>
    </nav>
  );
};
