
export const initTabListener = () => {

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    window.addEventListener('keydown', (e)=>{
      if (e.key === 'Tab') {
        document.body.classList.add('keyboard-user');
      }
    });
  }

};