import React from 'react';

import { Link } from 'components/link/Link';

import s from './NavLink.scss';

interface IProps {
  name: string;
  to: string;
}

export const NavLink = ({ name, to }: IProps) => {
  const isLink = typeof to !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  const content = () => <>{name} <span aria-hidden="true">{name}</span> </>;

  if (isExternal) {
    return (
      <a className={s.link} target="_blank" rel="noopener noreferrer" href={to}>
        {content()}
      </a>
    );
  }

  return (
    <Link className={s.link} activeClassName={s.linkActive} to={to} partiallyActive={false}>
      {content()}
    </Link>
  );
};
