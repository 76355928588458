import React from "react";
import s from "./Modal.scss";
import { UIContext } from "context/ui";
import { useContext } from "react";
import { Link } from "components/link/Link";

export const Modal = () => {
  const { desktopModal, toggleDesktopModal } = useContext(UIContext);

  return (
    <div className={s("modal", { show: desktopModal })}>
      <div className={s("linkContainer")}>
        <Link className={s("link")} to="/rides">
          Rides
        </Link>
      </div>

      <div className={s("linkContainer")}>
        <Link className={s("link")} to="https://showcase.aurora.tech/">
          Product Showcase
        </Link>
      </div>

      <div className={s("linkContainer")}>
        <Link className={s("link")} to="/company">
          About Aurora
        </Link>
      </div>
      <div className={s("linkContainer")}>
        <Link className={s("link")} to="/culture" target="_blank">
          Company Culture
        </Link>
      </div>

      <div className={s("linkContainer")}>
        <Link className={s("link")} to="https://ir.aurora.tech/">
          Investor Relations
        </Link>
      </div>

      <div className={s("linkContainer")}>
        <Link className={s("link")} to="https://info.aurora.tech/events">
          Events
        </Link>
      </div>

      <div className={s("linkContainer")}>
        <Link className={s("link")} to="/blog">
          Blog
        </Link>
      </div>

      <div className={s("linkContainer")}>
        <Link className={s("link")} to="/press">
          Press / Media
        </Link>
      </div>
    </div>
  );
};
