import React, { useContext, useEffect, useState } from 'react';

import { Prismic_Shared_ContentsuiConnectionConnection } from  'types/prismic';
import { StaticQuery, graphql } from 'gatsby';

import { Link } from 'components/link/Link';
import { NavLink } from 'components/nav/NavLink';
import { browserTargeting } from 'utils/browserTargeting';
import FbIcon from 'assets/svg/share-fb-white.svg';
import TwIcon from 'assets/svg/share-tw-white.svg';
import InIcon from 'assets/svg/share-in-white.svg';
import YtIcon from 'assets/svg/share-yt.svg';
import IgIcon from 'assets/svg/share-ig.svg';
import { navigate } from 'gatsby';
import Logo from 'assets/svg/logo.svg';
import LocationIcon from 'assets/svg/location.svg';

import { UIContext } from 'context/ui';

import s from './Footer.scss';

interface IFooterProps {
  prismic: { allShared_contentsuis: Prismic_Shared_ContentsuiConnectionConnection };
}

const FooterComponent = ({ contentfulData }: any) => {

  const locations = contentfulData.locations;
  const footerNav = contentfulData.footerLinks1;
  const footerCopy = contentfulData.footerLinks2;
  const footerSocial = contentfulData.socialLinks;
  const [isSafari, setIsSafari] = useState<Boolean>(false)

  const { isMobile, isDesktop } = useContext(UIContext);

  const socialIcons = {
    'facebook': <FbIcon className={s.socialIcon}/>,
    'twitter': <TwIcon className={s.socialIcon}/>,
    'linkedin': <InIcon className={s.socialIcon}/>,
    'instagram': <IgIcon className={s.socialIcon}/>,
    'youtube': <YtIcon className={s.socialIcon}/>
  }
  const contentOffices = locations?.map((location:string, index:number) => {
    return <li key={index}><LocationIcon className={s.locationIcon} ></LocationIcon> <span>{location}</span></li>;
  });

  const footerLinks = [
    {
      label: 'Aurora Driver',
      url: '/aurora-driver'
    },
    {
      label: 'Freight',
      url: '/freight'
    },
    {
      label: 'Rides',
      url: '/rides'
    },
    {
      label: 'Careers',
      url: '/careers'
    },
     {
      label: 'News',
      url: '/newsroom'
    }
  ]

  const copyLinks = footerCopy?.map((item:any, index:number) => {
    return <Link key={item.id} to={item.destination}>{item.label} </Link>;
  });

  const socialLinks = footerSocial?.map((item:any, index:number) => {
    return <li key={item.id}><a target='_blank' href={item.destination}>{socialIcons[item.label.toLowerCase()]} </a></li>;
  });

  useEffect(()=>{
    const browser = browserTargeting()
    browser!.IS_SAFARI && setIsSafari(true)
  }, [])

  return (
    <footer className={s('footer', isSafari ? 'isSafari' : '' )}>
      {/*<Container>*/}
        <div className={s.logoSection}>
          <div className={s.hey}>
            <Link to="/" className={s.hey}>
              <span className={`u-visually-hidden`}>Aurora</span>
              <Logo className={s.logo} />
            </Link>
          </div>
        </div>
        <div className={s.links1}>
            <div className={s.list}>
              <h2 className="u-visually-hidden" id="footer-nav-heading">Footer Navigation</h2>
              <ul className={s.footer__row__middle__nav} aria-labelledby="footer-nav-heading">
                <li>
                  <h4>Aurora</h4>
                </li>
                {
                  footerLinks.map( footerLink => (
                    <li>
                      <NavLink name={footerLink.label} to={footerLink.url} />
                    </li>
                  ))
                }
              </ul>
            </div>
        </div>
        <div className={s.links2}>
            <div className={s.list}>
              <h2 className="u-visually-hidden" id="footer-nav-heading">Footer Navigation</h2>
              <ul className={s.footer__row__middle__nav} aria-labelledby="footer-nav-heading">
                
                <li>
                  <h4>Company</h4>
                </li>
                <li>
                  <NavLink name={'Investor Relations'} to={'https://ir.aurora.tech/'} />
                </li>
                <li>
                  <NavLink name={'Safety'} to={'/safety'} />
                </li>
                <li>
                  <NavLink name={'Press/Media'} to={'/press'} />
                </li>
              </ul>
            </div>
        </div>
        <div className={s.locations}>

        {
                isDesktop &&
              <div className={s.list}>
                <h2 className="u-visually-hidden" id="offices-nav-heading">Offices Navigation</h2>
                <ul className={s.footer__locations} aria-labelledby="offices-nav-heading">
                <h4>Locations</h4>
                  {contentOffices}
                  </ul>
              </div>
        }
        </div>
        <div className={s.newsletter}>
          
              <div className={s.list}>
                <h4>Follow Us</h4>
                <h2 className="u-visually-hidden" id="social-nav-heading">Social Navigation</h2>
                <ul className={s.socialLinks} aria-labelledby="social-nav-heading">
                  {socialLinks}
                 
                </ul>
              </div>
        </div>
        
        <div className={s.rights}>
          <div className={s.text}>
            <p>All Rights Reserved © {contentfulData.year} Aurora Innovation Inc. </p>
            <div className={s.copyLinks}>
              {copyLinks}
              {
                <button type="button" id="hs_show_banner_button"
                  onClick={() =>{
                      const _hsp = window._hsp = window._hsp || [];
                      _hsp.push(['showBanner']);
                  }}
                  
                >
                  Cookie Settings
                </button>
              }
            </div>
          </div>
        </div>
      {/*</Container>*/}
    </footer>
  );
};

export const footerQuery = graphql`
  {
    contentfulData: contentfulNavigation {
      socialLinks {
        id
        destination
        label
      }
      footerLinks1 {
        id
        label
        destination
      }
      footerLinks2 {
        id
        destination
        label
      }
      locations
      year
    }
  }
`;

export const Footer = () => {

  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={FooterComponent}
    />
  );
};

