import { Link } from "components/link/Link";
import React, { useState } from "react";
import s from './ExpandableItem.scss';
import ArrowIcon from 'assets/svg/expand-arrow.svg';


export const ExpandableItem = ({list}: any) => {
    const [toggle, setToggle] = useState(false);
    const {title, items} = list;

    return (
        <div className={s('container')}>
            <div className={s('title')}>
                <Link activeClassName={s('activeLink')}  className={s('title')} to={title.href}>
                    {title.label}
                </Link>
                <ArrowIcon  className={s('arrowIcon', {active: toggle})} onClick={() => {setToggle(!toggle)}}/>
            </div>
            <div className={s('items', {toggled: toggle})}>
                {
                    items.map((item: any, i: number) => (

                        <Link activeClassName={s('activeLink')}  className={s('item')} to={item.href} key={i}>
                            {item.label}
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}