import React from "react";
import s from './DesktopModal.scss';
import { UIContext } from 'context/ui';
import { useContext } from "react";
import { Button } from "./button/Button";
import { Modal } from "./modal/Modal";


export const DesktopModal = () => {
    return (
        <div className={s('container')}>
            <Button/>
            <Modal/>
        </div>
    )
}