import React from "react";
import s from './Button.scss';
import { UIContext } from 'context/ui';
import { useContext } from "react";
import Hamburger from 'assets/svg/hamburger-gray.svg';
import Close from 'assets/svg/close-icon-grey.svg';
import { useState } from "react";

export const Button = () => {
    const { desktopModal, toggleDesktopModal } = useContext(UIContext);
    const [swipeIn, setSwipeIn] = useState(true);
    const handleClick = () => {
        setSwipeIn(!desktopModal);
        setTimeout(() => {
            toggleDesktopModal(!desktopModal);
        }, 1000)
        
    }
    return (
        <button className={s('button')}
            onClick={() => toggleDesktopModal(!desktopModal)}
        >
            {
                !desktopModal ?
                <Hamburger className={s('icon',  {
                    'iconIn': swipeIn,
                    'iconOut': !swipeIn,
                })}/> :
                <Close className={s('icon',  {
                    'iconIn': swipeIn,
                    'iconOut': !swipeIn,
                })}/>
            }
        </button>
    )
}