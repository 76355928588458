import React, { ReactNode, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';

import SmoothScroll from "components/scroll/AsScroll"
import { useContext } from 'react';
import { helmet } from '../../utils/helmet';
import { Header } from '../header/Header';
import { Preloader } from '../preloader/Preloader';
import { Devtools } from '../devtools/Devtools';
import { PageTransition } from '../page-transition/PageTransition';
import { Footer } from 'components/footer/Footer';
import { browserTargeting } from 'utils/browserTargeting';
import { initTabListener } from 'utils/tabListener';
// import { initStats } from 'utils/stats.js';
import { UIContext } from 'context/ui';
import { initCustomEasings } from 'utils/CustomEasings'
import { isIpad } from 'utils/browserTargeting'

import s from './AppLayout.scss';


interface IProps {
  children: ReactNode;
  location: {
    pathname: string;
  };
}

const isDev = process.env.NODE_ENV === 'development';

declare global {
  interface Window {
    smoothScroll:any;
    reactTransitionGroupTriggered:boolean;
    careersLastActiveCategory: string;
    careersLastActiveLocation: string;
    careersLastSearchString: string;
    newsLastActiveFilter: string;
    newsLastActiveFilterSubcategory: string;
    newsLastNewsSearchString: string;
    newsLastArticleLimit: number;
  }
}

// tslint:disable no-default-export
export default ({ children, location }: IProps) => {
  const { toggleDesktopModal } = useContext(UIContext);
  const wipeRef = useRef<HTMLDivElement>(null)
  initCustomEasings()
  initTabListener()

  useEffect(() => {
    // Fade in the content below the hero, after the hero entrance is triggered (only the 1st time the page load)
    const belowTheHero = document.querySelector('.below-the-hero');

    if (belowTheHero) {
      gsap.set('.below-the-hero', { opacity: 0 });
      gsap.to('.below-the-hero', { opacity: 1.2, duration: 1, delay: 3, ease: 'Power3.easeOut'});
    }

    // Add Safari class to body
    const browser = browserTargeting()
    browser!.IS_SAFARI && document.querySelector('html').classList.add('is-safari')
    isIpad() && document.querySelector('html').classList.add('is-ipad')

  }, [])

  useEffect(() => {
    toggleDesktopModal(false);
  }, [location])

  return (
    <div className={s.layout}>
      <Helmet {...helmet} />

      <Header />

      <SmoothScroll callbacks={location} />

      <Preloader />

      <div data-scroll-container>
        <div data-scroll-inner>
          <PageTransition location={location} wipeRef={wipeRef}>
            <main>
              {children}
            </main>
          </PageTransition>
          <Footer/>
        </div>
      </div>

      <div className={s.pageTransition} ref={wipeRef}></div>

      {isDev && <Devtools />}

    </div>
  );
};
