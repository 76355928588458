import * as React from 'react';
import { Breakpoints } from 'constants/Breakpoints'

interface ISizes {
  width: number;
  height: number;
}

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
  	var context = this, args = arguments;
  	clearTimeout(timeout);
  	timeout = setTimeout(function() {
  		timeout = null;
  		if (!immediate) func.apply(context, args);
  	}, wait);
  	if (immediate && !timeout) func.apply(context, args);
  };
}

export const useResize = () => {
  const [sizes, setSizes] = React.useState<ISizes>({ width: 0, height: 0 });
  const [isMobile, setMobile] = React.useState<boolean | null>(null);
  const [isDesktop, setDesktop] = React.useState<boolean | null>(null);

  const onResize = debounce(() => {
    if (typeof window === undefined) {
      return;
    }

    setSizes({ width: window.innerWidth, height: window.innerHeight });
    setMobile(window.matchMedia(`(max-width: ${Breakpoints.maxTablet}px)`).matches);
    setDesktop(window.matchMedia(`(min-width: ${Breakpoints.minTablet}px)`).matches);
  }, 100);

  React.useEffect(() => {
    if (typeof window === undefined) {
      return;
    }

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return {
    isMobile,
    isDesktop,
    sizes,
  };
};
